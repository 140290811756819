import { Component, EventEmitter, Output, inject } from "@angular/core";
import { AuthService } from "../../core/services/auth/auth.service";
import { FormsModule } from "@angular/forms";
import {
  UserDomains,
  UserType,
  contextSwitch,
} from "../../interface/interface-list";
import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ToastService } from "../../toast.service";
import { DataSharingService } from "../../product/dataSharing/data-sharing.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ApiServiceService } from "../../product/api-service/api-service.service";
import { UserManagementService } from "../../user-management/user-management/user-management/openapi";
import { LoaderComponent } from "../../loader/loader.component";

@Component({
  standalone: true,
  selector: "app-login",
  imports: [FormsModule, CommonModule, LoaderComponent],
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent {
  @Output() switchToContext = new EventEmitter<string>();
  userDomains = Object.values(UserDomains);
  // private http = inject(this.toastService);

  userDomain: string = "";

  credentials = {
    username: "",
    password: "",
  };
  contextS = contextSwitch;
  contexts = Object.values(contextSwitch);
  username: any = null;
  password: any = null;
  oldpassword: any = null;
  newpassword: any = null;
  phone: any;
  userTypeConst = UserType;
  userType: any = "";
  changePassword: boolean = false;
  isLoading: boolean = false;
  email: any;
  otp: any;
  changePasswordNew: boolean = false;
  changePasswordotp: boolean = false;
  newpasswordfield: any;
  confirmpassword: any;
  showPassword: boolean = false;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  constructor(
    private authService: AuthService,
    private route: Router,
    private location: Location,
    private toastService: ToastService,
    private roleRestrictionAPiService: UserManagementService,
    private data: DataSharingService,
    private apiService: ApiServiceService
  ) {}

  ngOnInit() {
    //code to extract user type from the URL
    let urlSegments = this.location.path().split("/");
    // this.userType1 = urlSegments[1];
    this.userType = this.userTypeConst.backoffice;
    // console.log("User type1 = ", this.userType1);

    console.log("User type = ", this.userType);
  }
  changePass(res: any) {
    this.changePasswordotp = res;
    this.login = false;
  }
  v: any;
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword; // Toggle the visibility
  }
  onSubmit() {
    this.credentials.username = this.username;
    this.credentials.password = this.password;
    // this.authService.currentUser.set("DAEFA")

    // this.authService.doLoginUser("FRE","Bie")
    // console.log("AXDad", this.authService.currentUser())
    this.toastService.showMessage("success", "fghjhjj");
    sessionStorage.setItem("loginUserName", this.username);
    this.isLoading = true;
    this.authService
      .login(this.username, this.password, this.userType, this.v)
      .subscribe({
        next: (v: any) => {
          console.log(v);
          if (v?.responseCode == 200) {
            this.isLoading = false;
            // alert(v?.message);
            alert("Logged In Successfully");
            this.authService.doLoginUser(this.username, v.token, v);
            let token = v?.token;
            const helper = new JwtHelperService();
            let decodedToken = helper.decodeToken(JSON.stringify(token));
            this.getRoleData(decodedToken.userId);
            sessionStorage.setItem("loginUserId", decodedToken?.userId);
            console.log(decodedToken);
            localStorage.setItem("JWT_TOKEN", v.token);
          } else if (v?.responseCode == 2) {
            this.isLoading = false;
            alert(v?.message);
            this.changePassword = true;
            localStorage.setItem("JWT_TOKEN", v.token);
          } else if (v?.responseCode == 0) {
            this.isLoading = false;
            alert(v?.message);
          }
        },
        error: () => {
          this.isLoading = false;
          alert("Error Please Try Again");
        },
      });
  }
  getRoleData(UserID: any) {
    // this.isLoading = true
    this.roleRestrictionAPiService.getRoleById(UserID).subscribe({
      next: (res: any) => {
        if (res) {
          // this.isLoading = true
          this.data.setSubjectData(res);
        }
      },
      error: () => {
        // this.isLoading = false
        alert("Error While Fetching Roles Data");
      },
    });
  }
  resetPwdData: any = false;
  resetPwd: boolean = true;
  otpVerify: boolean = false;
  login: boolean = true;
  sendOtp() {
    let body = {
      email: this.email,
      userType: "BO",
    };
    // this.dataSharing.show();
    this.isLoading = true;
    this.apiService.generateOtp(body).subscribe({
      next: (res) => {
        if (res?.responseCode == 200) {
          this.isLoading = false;
          alert("OTP has sent to your mail");
          this.otpVerify = true;
          this.changePassword = false;
          this.login = false;
          this.changePasswordotp = false;
        } else {
          this.isLoading = false;
          // this.dataSharing.hide();
          alert(res?.error);
        }
      },
      error: () => {
        this.isLoading = false;
        alert("Error Try Again!!!");
      },
    });
  }

  onSaveOtp() {
    let email = this.email;

    let body = {
      email: email,
      otp: this.otp,
    };
    this.isLoading = true;
    this.apiService.verifyOtp(body).subscribe({
      next: (res) => {
        console.log(res);
        if (res?.responseCode == 200) {
          this.isLoading = false;
          this.resetPwdData = true;
          this.otpVerify = false;
          this.resetPwdData = true;
          this.changePasswordNew = true;
          this.changePassword = false;
          this.changePasswordotp = false;
          this.login = false;
          alert("OTP Verified Successfully");
        } else {
          this.isLoading = false;
          alert(res?.error);
        }
      },
      error: () => {
        this.isLoading = false;
        alert("Error Try Again!!!");
      },
    });
  }

  pwdReset() {
    let body = {
      email: this.email,
      password: this.newpasswordfield,
      userType: "BO",
      otp: this.otp,
    };
    console.log(body);
    this.isLoading = true;
    // this.dataSharing.show()
    this.apiService.forgotPwd(body).subscribe({
      next: (res) => {
        if (res?.responseCode == 200) {
          this.isLoading = false;
          alert("Password Reset Successful");
          this.changePasswordNew = false;
          this.changePassword = false;
          this.login = true;
          // this.dataSharing.hide()
          this.otpVerify = false;
          this.resetPwdData = false;
        } else {
          this.isLoading = false;
          // this.dataSharing.hide()
          alert("Error Try Again");
        }
      },
      error: () => {
        this.isLoading = false;
        alert("Error Try Again");
      },
    });
  }

  changePasswordApi() {
    let body = {
      oldPassword: this.oldpassword,
      newPassword: this.newpassword,
    };
    console.log(body);

    this.isLoading = true;
    this.apiService.changePassword(body).subscribe({
      next: (res) => {
        console.log(res);
        if (res?.responseCode == 200) {
          this.isLoading = false;
          alert(res?.data);
          this.changePassword = false;
        } else {
          this.isLoading = false;
          alert(res?.error);
        }
      },
      error: () => {
        this.isLoading = false;
        alert("Error Try Again");
      },
    });
  }

  invokeSwitchTo(context: contextSwitch) {
    console.log("(((((((((((((");
    this.switchToContext.emit(context);
  }

  onKeyPress_onlyNumber(event: KeyboardEvent): void {
    const charCode = event.key;
    if (isNaN(Number(charCode))) {
      event.preventDefault();
    }
  }
}
